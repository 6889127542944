// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-about-ar-js": () => import("./../src/pages/about/about.ar.js" /* webpackChunkName: "component---src-pages-about-about-ar-js" */),
  "component---src-pages-about-about-en-js": () => import("./../src/pages/about/about.en.js" /* webpackChunkName: "component---src-pages-about-about-en-js" */),
  "component---src-pages-contact-contact-ar-js": () => import("./../src/pages/contact/contact.ar.js" /* webpackChunkName: "component---src-pages-contact-contact-ar-js" */),
  "component---src-pages-contact-contact-en-js": () => import("./../src/pages/contact/contact.en.js" /* webpackChunkName: "component---src-pages-contact-contact-en-js" */),
  "component---src-pages-index-index-ar-js": () => import("./../src/pages/index/index.ar.js" /* webpackChunkName: "component---src-pages-index-index-ar-js" */),
  "component---src-pages-index-index-en-js": () => import("./../src/pages/index/index.en.js" /* webpackChunkName: "component---src-pages-index-index-en-js" */),
  "component---src-pages-solutions-attractants-ar-js": () => import("./../src/pages/solutions/attractants.ar.js" /* webpackChunkName: "component---src-pages-solutions-attractants-ar-js" */),
  "component---src-pages-solutions-attractants-en-js": () => import("./../src/pages/solutions/attractants.en.js" /* webpackChunkName: "component---src-pages-solutions-attractants-en-js" */),
  "component---src-pages-solutions-ecosafe-en-js": () => import("./../src/pages/solutions/ecosafe.en.js" /* webpackChunkName: "component---src-pages-solutions-ecosafe-en-js" */),
  "component---src-pages-solutions-fungsafe-ar-js": () => import("./../src/pages/solutions/fungsafe.ar.js" /* webpackChunkName: "component---src-pages-solutions-fungsafe-ar-js" */),
  "component---src-pages-solutions-fungsafe-en-js": () => import("./../src/pages/solutions/fungsafe.en.js" /* webpackChunkName: "component---src-pages-solutions-fungsafe-en-js" */),
  "component---src-pages-solutions-lures-ar-js": () => import("./../src/pages/solutions/lures.ar.js" /* webpackChunkName: "component---src-pages-solutions-lures-ar-js" */),
  "component---src-pages-solutions-lures-en-js": () => import("./../src/pages/solutions/lures.en.js" /* webpackChunkName: "component---src-pages-solutions-lures-en-js" */),
  "component---src-pages-solutions-masstrapping-ar-js": () => import("./../src/pages/solutions/masstrapping.ar.js" /* webpackChunkName: "component---src-pages-solutions-masstrapping-ar-js" */),
  "component---src-pages-solutions-masstrapping-en-js": () => import("./../src/pages/solutions/masstrapping.en.js" /* webpackChunkName: "component---src-pages-solutions-masstrapping-en-js" */),
  "component---src-pages-solutions-prosafe-ar-js": () => import("./../src/pages/solutions/prosafe.ar.js" /* webpackChunkName: "component---src-pages-solutions-prosafe-ar-js" */),
  "component---src-pages-solutions-prosafe-en-js": () => import("./../src/pages/solutions/prosafe.en.js" /* webpackChunkName: "component---src-pages-solutions-prosafe-en-js" */),
  "component---src-pages-solutions-solutions-ar-js": () => import("./../src/pages/solutions/solutions.ar.js" /* webpackChunkName: "component---src-pages-solutions-solutions-ar-js" */),
  "component---src-pages-solutions-solutions-en-js": () => import("./../src/pages/solutions/solutions.en.js" /* webpackChunkName: "component---src-pages-solutions-solutions-en-js" */),
  "component---src-pages-solutions-tricosafe-ar-js": () => import("./../src/pages/solutions/tricosafe.ar.js" /* webpackChunkName: "component---src-pages-solutions-tricosafe-ar-js" */),
  "component---src-pages-solutions-tricosafe-en-js": () => import("./../src/pages/solutions/tricosafe.en.js" /* webpackChunkName: "component---src-pages-solutions-tricosafe-en-js" */),
  "component---src-pages-solutions-virosafe-ar-js": () => import("./../src/pages/solutions/virosafe.ar.js" /* webpackChunkName: "component---src-pages-solutions-virosafe-ar-js" */),
  "component---src-pages-solutions-virosafe-en-js": () => import("./../src/pages/solutions/virosafe.en.js" /* webpackChunkName: "component---src-pages-solutions-virosafe-en-js" */)
}

